import axios from 'axios';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';

import ConfigurationController from './Configuration.controller';
import CustomizationStore from './customization/CustomizationStore';
import GeneralStore from './general/GeneralStore';
import WebhookStore from './webhook/WebhookStore';
import FeatureStore from './customization/features/FeatureStore';
import ModalStore from 'components/common/modals/ModalStore';
import GeoLocationStore from '../geo-location-services/GeolocationStore';
import PisPaymentNetworkStore from './pis-payment-networks/PisPaymentNetworkStore';

// tabs
import TabAuthenticationKeys from './additionalTabs/TabAuthenticationKeys';
import TabRefundCertificate from './additionalTabs/TabRefundCertificate';
import TabCertificates from 'components/certificate-management/certificates/TabCertificates';
import TabCreateCSR from 'components/certificate-management/create-certificate/TabCreateCSR';
import TabAISConsent from './customization/consent/ais/TabAISConsent';
import TabPISConsent from './customization/consent/pis/TabPISConsent';
import TabVRPConsent from './customization/consent/vrp/TabVRPConsent';
import TabCSS from './customization/css/TabCSS';
import BankSelection from './customization/features/bank-selection/BankSelection';
import Consent from './customization/features/consent/Consent';
import TabFeatureControl from './customization/features/feature-control/TppFeatureControl';
import General from './customization/features/general/General';
import WebAppV2Store from './customization/web-app-v2/WebAppV2Store';
import TabTextTranslations from './textTranslations/TabTextTranslations';
import TabWebhook from './webhook/TabWebhook';
import PaymentInitiationRestrictionsStore from './payment-initiation-restrictions/PaymentInitiationRestrictionsStore';
import TabPaymentInitiationRestrictions from './payment-initiation-restrictions/TabPaymentInitiationRestrictions';
import ManageIpAddressStore from './manage-ip-addresses/ManageIpAddressesStore';
import TabManageIpAddress from './manage-ip-addresses/TabManageIpAddresses';
import TabGeoLocation from '../geo-location-services/TabGeoLocation';
import TabPisPaymentNetworks from './pis-payment-networks/TabPisPaymentNetworks';
import ProductVersionsStore from './productVersions/ProductVersionsStore';

// helpers
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

// constants
import { ADMIN, BNPP } from 'constants/banks';
import { RESELLER, UNDER_RESELLER } from 'constants/clientType';
import {
    AIS,
    PIS,
    REFUND,
    VARIABLE_RECURRING_PAYMENT,
    STANDING_ORDER,
    SINGLE_IMMEDIATE_PAYMENT,
    FUTURE_DATED,
    BULK_TRANSFER,
} from 'constants/memberPermissions';
import { TYPE_ONE, TYPE_TWO } from 'constants/memberTypes';
import TabProductVersions from './productVersions/TabProductVersions';
import TabGeneral from './general/TabGeneral';
import { SUPER_ADMIN } from 'constants/roles';
const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

const defaultSettings = { isChildConfigurationView: false, isAdminDashboard: false };

class ConfigurationStore {
    constructor(AppStore, { isChildConfigurationView, isAdminDashboard } = defaultSettings) {
        this.GeneralStore = new GeneralStore(this);
        this.WebhookStore = new WebhookStore(this);
        this.PaymentInitiationRestrictionsStore = new PaymentInitiationRestrictionsStore(this);
        this.PisPaymentNetworkStore = new PisPaymentNetworkStore(this);
        this.ManageIpAddressesStore = new ManageIpAddressStore(this);
        this.CustomizationStore = new CustomizationStore(this);
        this.FeatureStore = new FeatureStore(this);
        this.WebAppV2Store = new WebAppV2Store(this);
        this.BanksModalStore = new FeatureStore(this);
        this.GeoLocationStore = new GeoLocationStore(this);
        this.GenericErrorStore = new ModalStore();
        this.GenericSuccessStore = new ModalStore();
        this.UnsavedChangesModalStore = new ModalStore();
        this.ProductVersionsStore = new ProductVersionsStore();
        this.isChildConfigurationView = isChildConfigurationView;
        this.isAdminDashboard = isAdminDashboard;
        this.rootStore = AppStore;

        if (!_isEmpty(AppStore.member)) {
            this.AppStore = AppStore;
            this.member = AppStore.member;

            // used to manipulate tabs after render
            this.clientType = AppStore.client.clientType;
            this.memberId = AppStore.member.memberId;
            this.permissions = AppStore.member.permissions;
            this.memberType = AppStore.member.memberType;
            this.scope = AppStore.scope;

            // fetch only the DOMAIN alias realmId
            AppStore.member.aliases.forEach((alias) => {
                if (alias.type === 'DOMAIN') {
                    this.realmId = alias.realmId;
                    return;
                }
            });
        }
    }

    @observable
    memberEmail = '';
    @observable
    displayName = '';
    @observable
    allTabs = ConfigurationController.getTabs(this.clientType, this.memberType, this.permissions);
    @observable
    tabValue = Object.keys(this.allTabs)[0];
    @observable
    isSubTpp = false;
    @observable
    refId = '';
    @observable
    tppName = '';
    @observable
    tabStore = '';
    @observable
    tppFeatureControlForm = {};
    @observable
    fetchedTppFeatureControl = {};
    @observable
    settingsLoading = false;
    @observable
    isConsentTabValueChange = false;
    @observable
    hasForceMfa = false;

    @action
    setSubTppProps = (isSubTpp, refId) => {
        this.isSubTpp = isSubTpp;
        this.refId = refId;
    };

    @action
    updateTabValue = () => {
        this.tabValue = Object.keys(this.allTabs)[0];
    };

    @action
    initStore = (clientType, member, realmId, displayName, clientId) => {
        this.clientType = clientType;
        this.member = member;
        this.memberId = member.memberId;
        this.permissions = member.permissions;
        this.memberType = member.memberType;
        this.memberEmail = member.email;
        this.scope = member.scope;
        this.realmId = realmId;
        this.displayName = displayName;
        this.rootStore.CertificateManagementStore?.setEmailScope(
            this.memberEmail,
            this.scope,
            this.memberId,
        );
        this.rootStore.RefundCertificateManagementStore?.setEmailScope(
            this.memberEmail,
            this.scope,
            this.memberId,
        );
        this.rootStore.AuthenticationKeysStore?.setEmailScope(
            this.memberEmail,
            this.scope,
            this.memberId,
        );
        this.rootStore.RefundsStore?.setMemberIdAndScope(this.memberId, this.scope);
        this.rootStore.RefundsStore?.setEmail(this.memberEmail);
        this.ProductVersionsStore.setPermissionsAndClientId(this.permissions, clientId);
    };

    @action
    setTabs = () => {
        this.allTabs = ConfigurationController.getTabs(
            this.clientType,
            this.memberType,
            this.permissions,
        );
    };

    @action
    setIsConsentTabValueChange = (value) => {
        this.isConsentTabValueChange = value;
    };

    @action
    handleTabChange = (event, newTab) => {
        const _displayFeatures = _cloneDeep(this.FeatureStore.displayFeatures);
        delete _displayFeatures.country;
        const _features = _cloneDeep(this.FeatureStore.features);
        delete _features.country;
        if (this.tabValue.includes('Consent') && this.isConsentTabValueChange) {
            this.tabStore = newTab;
            this.UnsavedChangesModalStore.openModal();
        } else if (this.tabValue.includes('Consent') && !this.isConsentTabValueChange) {
            this.tabValue = newTab;
            this.CustomizationStore.setLanguage('');
        } else if (
            !_isEqual(_displayFeatures, _features) ||
            JSON.stringify(this.WebhookStore.defaultForm) !==
                JSON.stringify(this.WebhookStore.form) ||
            !this.FeatureStore.GeneralStore.isDefaultFields() ||
            !this.GeneralStore.isDefaultFields()
        ) {
            this.tabStore = newTab;
            this.UnsavedChangesModalStore.openModal();
        }
        if (newTab === 'certificates') {
            this.rootStore.CertificateManagementStore?.sendToCertificate();
            this.tabValue = newTab;
        }
        if (newTab === 'createCSR') {
            this.rootStore.CertificateManagementStore?.sendToCreateCSR();
            this.tabValue = newTab;
        } else {
            this.tabValue = newTab;
        }
    };

    @action
    handleUnsavedConfirm = () => {
        this.tabValue = this.tabStore;
        this.tabStore = '';
        this.UnsavedChangesModalStore.closeModal();
        this.WebhookStore.form = this.WebhookStore.defaultForm;
        this.FeatureStore.GeneralStore.setFieldsDefault();
        this.GeneralStore.setFieldsDefault();
        this.CustomizationStore.initCustomizations();
        this.CustomizationStore.setLanguage('');
        this.FeatureStore.setDisplayFeatures(this.FeatureStore.features);
    };

    @action
    hideTabs = flow(
        function* (AppStore) {
            try {
                const isAdminDashboardNonSubTppView = AppStore.scope === ADMIN && !this.isSubTpp;
                this.hasForceMfa =
                    (AppStore.scope === ADMIN ||
                        ([RESELLER, UNDER_RESELLER].includes(AppStore.client?.clientType) &&
                            [ADMIN, SUPER_ADMIN].includes(AppStore.user?.role))) &&
                    !this.isSubTpp;

                let tempTabs = { ...this.allTabs };
                if (this.clientType === UNDER_RESELLER) {
                    tempTabs = yield this.fetchSubTppConfigs(tempTabs);
                }
                tempTabs = yield this.fetchFeatureControlledTabs(tempTabs, AppStore);
                if (
                    (this.realmId && AppStore.client?.clientType === RESELLER) ||
                    isAdminDashboardNonSubTppView
                ) {
                    const consentTabs = {};
                    if (this.permissions.includes(AIS)) {
                        consentTabs['aisConsent'] = TabAISConsent;
                    }

                    // PIS permission is a set of STANDING_ORDER, SINGLE_IMMEDIATE_PAYMENT, FUTURE_DATED, BULK_TRANSFER
                    if (
                        this.permissions.includes(PIS) ||
                        this.permissions.includes(SINGLE_IMMEDIATE_PAYMENT) ||
                        this.permissions.includes(FUTURE_DATED) ||
                        this.permissions.includes(STANDING_ORDER) ||
                        this.permissions.includes(BULK_TRANSFER)
                    ) {
                        consentTabs['pisConsent'] = TabPISConsent;
                    }

                    if (this.permissions.includes(VARIABLE_RECURRING_PAYMENT)) {
                        consentTabs['vrpConsent'] = TabVRPConsent;
                    }

                    delete tempTabs.general;
                    tempTabs = {
                        webhook: TabWebhook,
                        webappGeneral: General,
                        ...tempTabs,
                        consentTC: Consent,
                        ...consentTabs,
                        css: TabCSS,
                    };
                }

                if (this.hasForceMfa) {
                    tempTabs = {
                        general: TabGeneral,
                        ...tempTabs,
                    };
                }

                if (
                    (this.realmId && AppStore.client?.clientType === RESELLER) ||
                    isAdminDashboardNonSubTppView
                ) {
                    tempTabs = {
                        ...tempTabs,
                        settings: TabFeatureControl,
                    };
                }

                if (this.realmId && AppStore.client?.clientType === RESELLER) {
                    tempTabs = {
                        ...tempTabs,
                        paymentInitiationRestrictions: TabPaymentInitiationRestrictions,
                        manageIpAddresses: TabManageIpAddress,
                    };
                    if (AppStore.scope === BNPP) {
                        tempTabs = {
                            ...tempTabs,
                            geoLocation: TabGeoLocation,
                        };
                    }
                    tempTabs = {
                        ...tempTabs,
                        pisPaymentNetwork: TabPisPaymentNetworks,
                    };
                }

                if (
                    (this.realmId && AppStore.client?.clientType === RESELLER) ||
                    isAdminDashboardNonSubTppView
                ) {
                    const additionalTabs = {};

                    if (this.memberType === TYPE_TWO) {
                        additionalTabs['certificates'] = TabCertificates;
                        additionalTabs['createCSR'] = TabCreateCSR;
                    }
                    if (this.permissions.includes(REFUND)) {
                        additionalTabs['certRefund'] = TabRefundCertificate;
                    }
                    if (this.memberType === TYPE_ONE) {
                        additionalTabs['authenticationKeys'] = TabAuthenticationKeys;
                    }

                    tempTabs = {
                        ...tempTabs,
                        ...additionalTabs,
                    };
                }

                if (this.realmId && AppStore.client?.clientType === RESELLER) {
                    const additionalTabs = {};
                    additionalTabs['textTranslations'] = TabTextTranslations;

                    tempTabs = {
                        ...tempTabs,
                        ...additionalTabs,
                    };
                }

                if (isAdminDashboardNonSubTppView) {
                    tempTabs = {
                        productVersions: TabProductVersions,
                        ...tempTabs,
                    };
                }
                this.allTabs = { ...tempTabs };
                this.updateTabValue();
            } catch (e) {
                console.error(e);
                AppStore.errorStore.openErrorModal(e);
            } finally {
                this.settingsLoading = false;
            }
        }.bind(this),
    );

    @action
    fetchSubTppConfigs = flow(
        function* (tempTabs) {
            const subTppTabs = {
                bankSelection: BankSelection,
                css: tempTabs.css,
                pisConsent: tempTabs.pisConsent,
                ...(tempTabs.webappV2 && { webappV2: tempTabs.webappV2 }),
            };

            // only display bank selection, css, pis consent and webAppV2 tabs to subTpp
            if (this.isSubTpp) {
                const url = new URLSearchParams();
                url.append('id', this.refId);
                url.append('memberId', this.memberId);
                const subTpp = yield axios.get(`/api/member/sub-tpp?${url.toString()}`);
                this.tppName = subTpp.data.subTpp.name;
                return subTppTabs;
            }
            return tempTabs;
        }.bind(this),
    );

    @action
    fetchFeatureControlledTabs = flow(
        function* (tempTabs, AppStore) {
            const res = yield axios.get(`/api/tppIntegration/feature-control/${this.memberId}`);
            if (this.clientType === UNDER_RESELLER) {
                Object.keys(res.data).map((k) => {
                    if (!res.data[k] && k !== 'webAppV2') {
                        if (k === 'displayWebAppTextTranslations') {
                            delete tempTabs['textTranslations'];
                        }
                        if (k === 'consentTC') {
                            delete tempTabs.aisConsent;
                            delete tempTabs.pisConsent;
                            delete tempTabs.vrpConsent;
                        }
                        k === 'bankSelection' &&
                        AppStore.client?.clientType === RESELLER &&
                        this.realmId
                            ? ''
                            : delete tempTabs[k];
                    }
                });
            }
            if (!res.data['webappV2'] && AppStore.scope !== ADMIN) {
                delete tempTabs.webappV2;
            }
            return tempTabs;
        }.bind(this),
    );

    @action
    fetchTppFeatureControl = flow(
        function* (AppStore) {
            try {
                this.tppFeatureControlForm =
                    AppStore.scope === ADMIN && this.clientType === RESELLER
                        ? { webappV2: false }
                        : {
                            webappGeneral: false,
                            bankSelection: false,
                            consentTC: false,
                            css: false,
                            beneficiarySettings: false,
                            displayWebAppTextTranslations: false,
                            geoLocation: false,
                            webappV2: false,
                        };
                const res = yield axios.get(`/api/tppIntegration/feature-control/${this.memberId}`);
                this.fetchedTppFeatureControl = res.data;
                if (AppStore.scope === ADMIN) {
                    this.tppFeatureControlForm =
                        this.clientType === RESELLER ? { webappV2: !!res.data.webappV2 } : res.data;
                } else {
                    this.tppFeatureControlForm = res.data;
                    delete this.tppFeatureControlForm['webappV2'];
                }
            } catch (e) {
                console.error(e);
                AppStore.errorStore.openErrorModal(e);
            }
        }.bind(this),
    );

    @action
    setTppFeatureControl = flow(
        function* (AppStore) {
            try {
                this.settingsLoading = true;
                yield axios.put('/api/tppIntegration/feature-control', {
                    memberId: this.memberId,
                    tppFeatureControls: {
                        ...this.fetchedTppFeatureControl,
                        ...this.tppFeatureControlForm,
                    },
                });
                this.GenericSuccessStore.openModal();
            } catch (e) {
                console.error(e);
                AppStore.errorStore.openErrorModal(e);
            } finally {
                this.settingsLoading = false;
            }
        }.bind(this),
    );

    @action
    handleTppControlFormCheck = (e) => {
        this.tppFeatureControlForm[e.target.name] = e.target.checked;
    };
}

export default ConfigurationStore;
