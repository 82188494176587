import { RESELLER, UNDER_RESELLER } from '../../constants/clientType';
import { TYPE_ONE, TYPE_TWO, TYPE_THREE } from '../../constants/memberTypes';
import {
    AIS,
    PIS,
    VARIABLE_RECURRING_PAYMENT,
    CUSTOMIZE_FLOW,
    STANDING_ORDER,
    SINGLE_IMMEDIATE_PAYMENT,
    FUTURE_DATED,
    BULK_TRANSFER,
} from '../../constants/memberPermissions';
import TabAISConsent from './customization/consent/ais/TabAISConsent';
import TabPISConsent from './customization/consent/pis/TabPISConsent';
import TabVRPConsent from './customization/consent/vrp/TabVRPConsent';
import TabCSS from './customization/css/TabCSS';
import TabGeneral from './general/TabGeneral';
import TabWebhook from './webhook/TabWebhook';
import TabTextTranslations from './textTranslations/TabTextTranslations';

// features
import BankSelection from './customization/features/bank-selection/BankSelection';
import Consent from './customization/features/consent/Consent';
import General from './customization/features/general/General';
import WebAppV2 from './customization/web-app-v2/WebAppV2';
import { featureFlagStore } from '../../FeatureFlagStore';

export default class ConfigurationController {
    static getTabs(clientType, memberType, permissions) {
        const aisTabs = {};
        const pisTabs = {};
        const cssTabs = {};
        const featureTabs = {};
        const webAppV2Tab = {};

        cssTabs['css'] = TabCSS;
        featureTabs['webappGeneral'] = General;
        webAppV2Tab['webappV2'] = WebAppV2;
        featureTabs['bankSelection'] = BankSelection;
        featureTabs['consentTC'] = Consent;

        if (permissions.includes(AIS)) {
            aisTabs['aisConsent'] = TabAISConsent;
        }

        // PIS permission is a set of STANDING_ORDER, SINGLE_IMMEDIATE_PAYMENT, FUTURE_DATED, BULK_TRANSFER
        if (
            permissions.includes(STANDING_ORDER) ||
            permissions.includes(SINGLE_IMMEDIATE_PAYMENT) ||
            permissions.includes(FUTURE_DATED) ||
            permissions.includes(BULK_TRANSFER) ||
            permissions.includes(PIS)
        ) {
            pisTabs['pisConsent'] = TabPISConsent;
        }

        if (permissions.includes(VARIABLE_RECURRING_PAYMENT)) {
            pisTabs['vrpConsent'] = TabVRPConsent;
        }

        let allTabs = {
            general: TabGeneral,
            webhook: TabWebhook,
        };

        if (permissions.includes(CUSTOMIZE_FLOW)) {
            switch (memberType) {
                case TYPE_ONE:
                    if (clientType === UNDER_RESELLER) {
                        allTabs = {
                            general: TabGeneral,
                            webhook: TabWebhook,
                            bankSelection: BankSelection,
                            ...cssTabs,
                            ...webAppV2Tab,
                            pisConsent: TabPISConsent,
                        };
                    } else if (clientType === RESELLER) {
                        allTabs = {
                            webhook: TabWebhook,
                            ...featureTabs,
                            ...webAppV2Tab,
                        };
                    } else {
                        allTabs = {
                            general: TabGeneral,
                            webhook: TabWebhook,
                            bankSelection: BankSelection,
                            ...cssTabs,
                            ...webAppV2Tab,
                        };
                    }
                    break;
                case TYPE_TWO:
                    allTabs = {
                        webhook: TabWebhook,
                        ...featureTabs,
                        ...webAppV2Tab,
                        ...aisTabs,
                        ...pisTabs,
                        ...cssTabs,
                        textTranslations: TabTextTranslations,
                    };
                    break;
                case TYPE_THREE:
                    allTabs = {
                        webhook: TabWebhook,
                    };
                    break;
            }
        }
        return allTabs;
    }

    /* eslint-disable no-fallthrough*/
    static renameTabsForWebappV2(tab) {
        if (featureFlagStore.isFeatureEnabled('enableConfigurationTabsRename')) {
            switch (tab) {
                case 'webappGeneral':
                    return 'webappGeneralV1';
                case 'aisConsent':
                    return 'aisConsentV1';
                case 'pisConsent':
                    return 'pisConsentV1';
                case 'css':
                    return 'cssV1';
                case 'bankSelection':
                    return 'bankSelectionV1V2';
                case 'textTranslations':
                    return 'textTranslationsV1';
                default:
                    return tab;
            }
        }
        return tab;
    }
}
